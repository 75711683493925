import { createRouter, createWebHistory } from 'vue-router'

import { useAuthStore } from '@/store/auth.js'

import Default from '@/layouts/default.vue'
import Empty from '@/layouts/empty.vue'
import Webview from '@/layouts/webview.vue'

const hostConfig = {
  [import.meta.env.VITE_NEW_YEAR_HOST_NAME]: {
    component: () => import('@/pages/new-year.vue'),
    meta: { layout: Empty },
  },
  [import.meta.env.VITE_HEROES_HOST_NAME]: {
    component: () => import('@/pages/projects/heroes/index.vue'),
    meta: { layout: Empty },
  },
}

const defaultConfig = {
  component: () => import('@/pages/index.vue'),
  meta: {
    layout: Default,
    refresh: false,
  },
  beforeEnter: (to, from) => {
    if (from.name) to.meta.refresh = true
  },
}

const selectedConfig = hostConfig[location.hostname] || defaultConfig

const landing = {
  path: '/',
  name: 'index',
  ...selectedConfig,
}

let routes = [
  landing,
  {
    path: '/ssa/login',
    name: 'ssa-login',
    component: () => import('@/pages/ssa/login.vue'),
    meta: {
      layout: Empty,
      guest: true,
    },
  },
  {
    path: '/projects/onp',
    name: 'onp',
    component: () => import('@/pages/onp-landing.vue'),
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/heroes',
    name: 'heroes',
    component: () => import('@/pages/projects/heroes/index.vue'),
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/onp/platform',
    name: 'platform',
    component: () => import('@/pages/platform-landing/index.vue'),
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/grants',
    name: 'grants',
    component: () => import('@/pages/projects/grants/index.vue'),
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/grants/federal',
    name: 'grants.federal',
    component: () => import('@/pages/projects/grants/federal.vue'),
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/grants/murman',
    name: 'grants.murman',
    component: () => import('@/pages/projects/grants/murman/index.vue'),
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/grants/murman/:id',
    component: () => import('@/pages/projects/grants/murman/[id].vue'),
    name: 'grants.murman.id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/grants/ideas',
    name: 'grants.ideas',
    component: () => import('@/pages/projects/grants/ideas.vue'),
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/grants/charity',
    name: 'grants.charity',
    component: () => import('@/pages/projects/grants/charity.vue'),
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/kns',
    name: 'kns',
    component: () => import('@/pages/projects/kurs-na-sever/index.vue'),
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/onp/platform/results',
    name: 'platform.results',
    component: () => import('@/pages/platform-landing/results.vue'),
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/onp/platform/offer',
    name: 'platform.offer',
    component: () => import('@/pages/platform-landing/offer.vue'),
    meta: {
      layout: Default,
    },
  },
  {
    path: '/profile',
    component: () => import('@/pages/profile/index.vue'),
    name: 'profile',
    meta: {
      layout: Default,
      auth: true,
    },
    children: [
      {
        path: '/profile/main',
        name: 'profile.main',
        component: () => import('@/pages/profile/main.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/notifications',
        name: 'profile.notifications',
        component: () => import('@/pages/profile/main.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/balance',
        name: 'profile.balance',
        component: () => import('@/pages/profile/balance.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/favorites',
        name: 'profile.favorites',
        component: () => import('@/pages/profile/favorites.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/auth',
        name: 'profile.auth',
        component: () => import('@/pages/profile/auth.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/referral',
        name: 'profile.referral',
        component: () => import('@/pages/profile/referral.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/messages',
        name: 'profile.messages',
        component: () => import('@/pages/profile/messages/index.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/messages/:id',
        name: 'profile.messages.id',
        component: () => import('@/pages/profile/messages/[id].vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/complaints',
        name: 'profile.complaints',
        component: () => import('@/pages/profile/complaints.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/votes',
        name: 'profile.votes',
        component: () => import('@/pages/profile/main.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/applications',
        name: 'profile.applications',
        component: () => import('@/pages/profile/main.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/initiatives',
        name: 'profile.initiatives',
        component: () => import('@/pages/profile/initiatives.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/archive',
        name: 'profile.archive',
        component: () => import('@/pages/profile/main.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/trainers',
        name: 'profile.trainers',
        component: () => import('@/pages/profile/trainers.vue'),
        meta: {
          auth: true,
          seo: {
            title: 'Народные тренеры',
            description:
              'Народное онлайн-голосование, чтобы выбрать лучшего спортивного тренера в Мурманской области.',
          },
          scrollPos: {
            top: 0,
            left: 0,
          },
          filters: true,
        },
      },
      {
        path: '/profile/teams',
        name: 'profile.teams',
        component: () => import('@/pages/profile/teams.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/tickets',
        name: 'profile.tickets',
        component: () => import('@/pages/profile/tickets.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/purchases',
        name: 'profile.purchases',
        component: () => import('@/pages/profile/purchases.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/profile/portfolio',
        name: 'profile.portfolio',
        component: () => import('@/pages/profile/portfolio.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/portfolios/:id',
    name: 'portfolios.id',
    component: () => import('@/pages/portfolios/[id].vue'),
    meta: {
      layout: Empty,
    },
  },
  // {
  //   path: "/projects/plan",
  //   component: () => import("@/pages/projects/plan.vue"),
  //   name: "projects",
  //   meta: {
  //     layout: Default,
  //   },
  // },
  {
    path: '/forms/:id',
    component: () => import('@/pages/forms/[id].vue'),
    name: 'forms.id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/teams',
    component: () => import('@/pages/projects/teams/index.vue'),
    name: 'projects.teams',
    meta: {
      seo: {
        title: 'Народные команды',
      },
      layout: Default,
    },
  },
  {
    path: '/webview/teams',
    component: () => import('@/pages/webview/contests/teams.vue'),
    name: 'webview.teams',
    meta: {
      layout: Webview,
    },
  },
  {
    path: '/projects/teams/:id',
    component: () => import('@/pages/projects/teams/[id].vue'),
    name: 'projects.teams.id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/trainers',
    component: () => import('@/pages/projects/trainers/index.vue'),
    name: 'projects.trainers',
    meta: {
      seo: {
        title: 'Народные тренеры',
      },
      layout: Default,
    },
  },
  {
    path: '/projects/trainers/:id',
    component: () => import('@/pages/projects/trainers/[id].vue'),
    name: 'projects.trainers.id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/webview/trainers',
    component: () => import('@/pages/webview/contests/trainers.vue'),
    name: 'webview.trainers',
    meta: {
      layout: Webview,
    },
  },
  {
    path: '/projects/improvements',
    component: () => import('@/pages/projects/improvements/index.vue'),
    name: 'projects.improvements',
    meta: {
      seo: {
        title: 'Благоустройство',
        description:
          'Здесь собраны все проекты по благоустройству Мурманской области.',
      },
      layout: Default,
    },
  },
  {
    path: '/projects/improvements/:id',
    component: () => import('@/pages/projects/improvements/[id].vue'),
    name: 'projects.improvements.id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects/child',
    component: () => import('@/pages/projects/child/index.vue'),
    name: 'projects.child',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/votings',
    component: () => import('@/pages/votings/index.vue'),
    name: 'votings',
    meta: {
      seo: {
        title: 'Опросы и голосования',
        description:
          'Этот сервис предоставляет возможность жителям Мурманской области активно участвовать в жизни своего региона, влиять на принятие решений и формировать будущее своей родины.',
      },
      layout: Default,
    },
  },
  {
    path: '/votings/:id',
    component: () => import('@/pages/votings/[id].vue'),
    name: 'votings.id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/webview/votings/:id',
    component: () => import('@/pages/webview/votings/[id].vue'),
    name: 'webview.votings.id',
    meta: {
      layout: Webview,
    },
  },
  {
    path: '/marketplace',
    component: () => import('@/pages/shop/index.vue'),
    name: 'shop',
    meta: {
      seo: {
        title: 'Магазин',
        description:
          'Получайте баллы за активность на портале, и обменивайте в магазине на фирменную продукцию',
      },
      layout: Default,
    },
  },
  {
    path: '/marketplace/:id',
    component: () => import('@/pages/shop/[id].vue'),
    name: 'shop.id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/complaints',
    component: () => import('@/pages/complaints/index.vue'),
    name: 'complaints',
    meta: {
      seo: {
        title: 'Обращение жителей',
        description:
          'Это удобный сервис для жителей города, с помощью которого можно быстро сообщать о возникающих проблемах и контролировать процесс их решения.',
      },
      layout: Default,
    },
  },
  {
    path: '/complaints/:id',
    component: () => import('@/pages/complaints/[id].vue'),
    name: 'complaints.id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/initiatives',
    component: () => import('@/pages/initiatives/index.vue'),
    name: 'initiatives',
    meta: {
      seo: {
        title: 'Инициативы',
        description:
          'Теперь Вы сами можете решать, как изменить город. Публикуйте собственные предложения и голосуйте за уже выдвинутые инициативы.',
      },
      layout: Default,
    },
  },
  {
    path: '/webview/initiatives',
    component: () => import('@/pages/webview/initiatives/index.vue'),
    name: 'webview.initiatives',
    meta: {
      layout: Webview,
    },
  },
  {
    path: '/initiatives/:id',
    component: () => import('@/pages/initiatives/[id].vue'),
    name: 'initiatives.id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/webview/initiatives/:id',
    component: () => import('@/pages/webview/initiatives/[id].vue'),
    name: 'webview.initiatives.id',
    meta: {
      layout: Webview,
    },
  },
  {
    path: '/projects/pnszh',
    component: () => import('@/pages/projects/pnszh/index.vue'),
    name: 'projects.pnszh',
    meta: {
      seo: {
        title: 'План «На Севере — жить!»',
        description:
          'Это народная стратегия, которую сформировали сами жители Мурманской области. А за ходом преображений по всему региону любой желающий может наблюдать на портале «Наш Север».',
      },
      layout: Default,
    },
  },
  {
    path: '/projects/pnszh/:id',
    component: () => import('@/pages/projects/pnszh/[id].vue'),
    name: 'projects.pnszh.id',
    meta: {
      layout: Default,
    },
    beforeEnter: async (to) => {
      if (to.query.linkstat) {
        try {
          const { resource } = await $http(`/improvements/${to.params.id}/old`)

          await $http(`/improvements/${resource.id}/stat`, {
            method: 'POST',
          })

          return { name: 'projects.pnszh.id', params: { id: resource.id } }
        } catch (e) {
          console.error(e)
        }
      }
    },
  },
  {
    path: '/socials/:social',
    component: () => import('@/pages/socials.vue'),
    name: 'socials',
    meta: {
      layout: Empty,
    },
  },
  {
    path: '/magic',
    component: () => import('@/pages/magic.vue'),
    name: 'magic',
    meta: {
      layout: Empty,
    },
  },
  {
    path: '/impersonate',
    component: () => import('./pages/impersonate.vue'),
    name: 'impersonate',
    meta: {
      layout: Empty,
    },
  },
  {
    path: '/afisha',
    component: () => import('./pages/afisha/index.vue'),
    name: 'afisha',
    meta: {
      seo: {
        title: 'Афиша',
        description: 'Узнайте о всех мероприятиях Мурманской области.',
      },
      scrollPos: {
        top: 0,
        left: 0,
      },
      filters: true,
      layout: Default,
    },
  },
  {
    path: '/afisha/:id',
    component: () => import('./pages/afisha/[id].vue'),
    name: 'afisha.id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/afisha/collections/:id',
    component: () => import('./pages/afisha/collections/[id].vue'),
    name: 'afisha.collections.id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/blog',
    component: () => import('./pages/blog/index.vue'),
    name: 'blog',
    meta: {
      seo: {
        title: 'Блог',
        description: 'Новости Мурманской области.',
      },
      scrollPos: {
        top: 0,
        left: 0,
      },
      filters: true,
      layout: Default,
    },
  },
  {
    path: '/blog/:id',
    component: () => import('./pages/blog/[id].vue'),
    name: 'blog.id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/about/general',
    component: () => import('@/pages/about/general.vue'),
    name: 'about',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/agreement',
    component: () => import('@/pages/agreement.vue'),
    name: 'agreement',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/policy',
    component: () => import('@/pages/policy.vue'),
    name: 'policy',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/map',
    component: () => import('@/pages/map.vue'),
    name: 'map',
    meta: {
      layout: Empty,
      seo: {
        title: 'Интерактивная карта',
        description:
          'Получайте баллы за активность на портале, и обменивайте в магазине на фирменную продукцию',
      },
    },
  },
  {
    path: '/cameras',
    component: () => import('@/pages/cameras.vue'),
    name: 'cameras',
    meta: {
      layout: Default,
      seo: {
        title: 'Камеры',
        description:
          'Получайте баллы за активность на портале, и обменивайте в магазине на фирменную продукцию',
      },
    },
  },
  {
    path: '/projects/national',
    component: () => import('./pages/projects/national/index.vue'),
    name: 'projects.national.index',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/new-year',
    component: () => import('@/pages/new-year.vue'),
    name: 'new-year-landing',
    meta: {
      layout: Empty,
    },
  },
  {
    path: '/videos',
    component: () => import('@/pages/videos/index.vue'),
    name: 'videos',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/videos/:albumId/:videoId?',
    component: () => import('@/pages/videos/[id].vue'),
    name: 'videos-album-id',
    meta: {
      layout: Default,
    },
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  if (from.name && (from.name.startsWith(to.name) || from.name === to.name)) {
    return savedPosition || to.meta?.scrollPos
  }
  return savedPosition || { left: 0, top: 0 }
}
/**
 *
 * @return {Object}
 */
export default function () {
  const router = createRouter({
    history: createWebHistory('/'),
    routes: [
      ...routes,
      {
        path: '/:pathMatch(.*)*',
        component: () => import('./pages/404.vue'),
        name: '404',
        meta: {
          layout: Default,
        },
      },
    ],
    scrollBehavior,
  })

  router.beforeEach((to, from, next) => {
    if (from.meta?.scrollPos && to.name && to.name.startsWith(from.name)) {
      from.meta.scrollPos.top = window.scrollY
      from.meta.scrollPos.left = 0
    }
    next()
  })

  router.beforeEach(async (to) => {
    const auth = useAuthStore()

    if (
      to.meta?.auth === true &&
      !auth.token &&
      !localStorage.getItem('logged-in-as-user')
    ) {
      auth.returnUrl = to.fullPath
      return '/'
    }

    if (
      to.meta?.guest === true &&
      (auth.token || localStorage.getItem('logged-in-as-user'))
    ) {
      auth.returnUrl = to.fullPath
      return '/'
    }
  })

  return router
}
